import Lazy, { LazyError } from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

export const _script_look_stories = {
  name: 'View user stories',
  description: `Will view all user stories, no sleeping.
  BETA FEATURE: Currently works on 1.4.6 version only
  `,
  isPRO: true,
  params: [
    {
      name: 'username',
      type: 'text',
      labelText: 'Username',
      prefix: '@',
      defaultValue: 'caffeinum',
    },
    // {
    //   name: 'nPhotos',
    //   type: 'number',
    //   labelText: 'Number of photos',
    //   defaultValue: Infinity,
    //   values: [1, 3, 10, 20, 50, 200, Infinity]
    // }
  ],
  run: async ({ shouldSleep = false, username }, printLog = console.log) => {
    printLog(`Fetching feed ... `)

    const { user } = await instagram.request({
      method: 'get_user_info',
      params: [username],
    })

    printLog(`Username @${user.username}`)

    const feed = await instagram.request({
      method: 'get_user_story_feed',
      params: [user.pk],
    })

    const { reel } = feed

    if (!reel) {
      printLog(`No stories to see!`)
      return
    }

    printLog(`Loaded ${reel.items.length} stories`)

    // const stories = new Lazy(makeGenerator(reel.items))
    const stories = reel.items

    const seen = await instagram.request({
      method: 'see_reels',
      params: [stories],
    })

    printLog(`Marked seen!`)

    //
    // const seen = stories
    //   .map(story => {
    //     return instagram.request({
    //       method: 'see_reels',
    //       params: [story]
    //     });
    //   })
    //   .unwrap(true)

    return

    // Phase 1: set up feed generator

    // const _feed = instagram.page_generator({
    //   method: 'get_user_story_feed',
    //   params: [user.pk]
    // });

    // Phase 2: page

    const items = new Lazy(feed)
      .peek((page, index) =>
        printLog(`Page ${index}: Fetched ${page.num_results} items.`),
      )
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .map(page => makeGenerator(page.feed_items))
      .flat() // Phase 3: Map each photo into like

    const liked = items
      .filter(item => item.media_or_ad)
      .map(item => item.media_or_ad)
      .filter((item, index) => {
        if (instagram.isStopped) {
          printLog(
            `Skipping ${index} ${instagramUrl(item)} : Request was skipped`,
          )
          return false
        }

        if (item.has_liked) {
          printLog(`Skipping ${index} ${instagramUrl(item)} : Already liked`)
          return false
        }

        return true
      })
      .take(nPhotos)
      .peek((item, index) =>
        printLog(`Liking item ${index}, ${instagramUrl(item)} ... `),
      )
      .map(item =>
        instagram.request({
          method: 'like',
          params: [item.id],
        }),
      )
      .peek(({ status }) => printLog(status, false))
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`)) // Phase 4: run. if nPhotos is given, take only that much

    const results = await liked.unwrap({
      accumulate: true,
    })
    printLog(`FINISHED,
      Total requests: ${results.length},
      Success: ${results.filter(item => item.status == 'ok').length} items,
      Errors: ${results.filter(item => item.status == 'error').length} items`)
    return results
  },
}

// scripts.look_stories = _script_look_stories;

export default _script_look_stories
