import spintax from 'spintax'

import Lazy, { LazyError } from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import {
  get_random,
  getCSV,
  download,
  instagramUrl,
  getURL,
  randomTimeout,
  sleep,
  skip,
} from './util'

import {
  safeMap,
} from '../old/likeItems'


export const comment_by_user = {
  name: 'Comment photos from user',
  params: [
    { name: 'username', type: 'text', prefix: '@', defaultValue: 'danokhlopkov' },
    { name: 'nPhotos', type: 'number', values: [1, 2, 3], defaultValue: 1 },
    { name: 'comments', type: 'textarea', defaultValue: '🔥 [[username]]\ngreat {photo|picture}!\nwow' },
  ],
  description: `
    Will put comments under user photos.

    It will chose random one from a list of comments.

    Also supports Spintax!
  `,
  run: async ({ username, nPhotos, comments }, printLog = console.log) => {
    if (!username) {
      throw new Error(`Empty username field!`)
    }

    printLog(`Fetching photos by username: @${username} ... `)

    const { user } = await instagram.request({
      method: 'get_user_info',
      params: [username],
    })

    const { items } = await instagram.request({
      method: 'get_user_feed',
      params: [user.pk],
    })

    printLog(`OK, ${items.length} results`, false)
    console.log(`URLS:`, items.map(instagramUrl))

    const comment_text = item => {
      const _comment = window.comment_text
        ? window.comment_text(item)
        : get_random(comments.split('\n')).replace('[[username]]', `@${item.user.username}`)

      const comment = spintax.unspin(_comment)

      printLog(`Posting comment to ${instagramUrl(item)} : ${comment}`)

      return comment
    }

    return safeMap(
      items.slice(0, nPhotos),
      item =>
        instagram.request({
          method: 'comment',
          params: [item.id, comment_text(item)],
        }),
      printLog,
    )
  },
}

export default comment_by_user
