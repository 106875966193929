import Lazy, { LazyError } from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import {
  get_random,
  getCSV,
  download_array,
  download,
  instagramUrl,
  getURL,
  randomTimeout,
  sleep,
  skip,
} from './util'


const populateEmail = user => {
  const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g

  const [email, ...others] = Object.values(user)
    .filter(value => typeof value == 'string')
    .flatMap(value => value.match(emailRegexp) || [])
  console.log('found emails (using only first):', [email, ...others])

  return {
    ...user,
    email,
  }
}

export const load_followings = {
  name: 'Load full list of user followings',
  params: [
    {
      name: 'username',
      type: 'text',
      prefix: '@',
      labelText: 'Username',
      defaultValue: 'caffeinum',
    },
    // {
    //   name: 'isFullInfo',
    //   type: 'checkbox',
    //   prefix: '',
    //   labelText:
    //     'Download full profile for each follower, including email, if there is one (takes much longer)',
    //   defaultValue: false,
    // },
    {
      name: 'isWhitelist',
      type: 'checkbox',
      prefix: '',
      labelText: 'Save as FOLLOWING WHITELIST',
      defaultValue: false,
    },
  ],
  run: async (
    { username, isFullInfo = false, isWhitelist = false },
    printLog = console.log,
  ) => {
    const {
      user: { pk },
    } = await instagram.request(
      { method: 'get_user_info', params: [username] },
      true,
    )

    if (!pk || isNaN(pk)) throw new Error(`No user id: ${pk}`)

    // Phase 1: set up feed generator
    const followings_list = instagram.page_generator({
      method: 'get_user_followings',
      params: [pk],
    })

    // Phase 2: pages to list
    const users = new Lazy(followings_list)
      .peek((page, index) =>
        printLog(
          `Batch ${index} of followings for @${username} loaded: ${page.users.length}`,
        ),
      )
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .map(page => makeGenerator(page.users))
      .flat()

    if (!isFullInfo) {
      const followings = await users.unwrap({ accumulate: true })

      printLog(`Followings for @${username} loaded: ${followings.length}`)
      printLog(`You can access them in window.users or download using`)
      printLog(`\t\tdownloadCSV()`)
      printLog(`or`)
      printLog(`\t\tdownload('followings_${username}.csv', getCSV(followings))`)

      window.followings = followings
      window.downloadCSV = () => {
        download_array(followings, `followings_${username}`)
      }

      downloadCSV()

      if (isWhitelist) {
        try {
          try {
            const old_whitelist = JSON.parse(localStorage.getItem('unfollow_whitelist'))

            if (old_whitelist) {
              const message = `
              You already have a whitelist with ${old_whitelist.length} items.

              Overwrite?
              `

              if (!window.confirm(err_message)) {
                return
              }
            }
          } catch (err) {

          }

          const my_followings = JSON.stringify(
            followings.map(user => ({ pk: user.pk, username: user.username }))
          )

          localStorage.setItem(`unfollow_whitelist`, my_followings)
          printLog(`Saved to whitelist: ${followings.length} items`)

        } catch (err) {
          printLog(`You can ignore this error: Cant save to localStorage: file too big.`)
          return
        }
      }

      try {
        localStorage.setItem(`followings_${username}`, JSON.stringify(followings))
      } catch (err) {
        printLog(`You can ignore this error: Cant save to localStorage: file too big.`)
      }

      return
    } else {

    }
  },
}

export default load_followings
