import Lazy, { LazyError } from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import {
  instagramUrl,
  sleep,
  progressive_sleep,
} from './util'

export const _script_look_stories = {
  name: 'View stories from your feed',
  description: `
    View stories of the people who like photos of @username:
  `,
  isPRO: true,
  params: [
    {
      name: 'username',
      type: 'text',
      labelText: 'Username',
      prefix: '@',
      defaultValue: 'mipt.ru',
    }
  ],
  run: async ({ username }, printLog = console.log) => {
    printLog(`Fetching feed ... `)

    const { user } = await instagram.request({
      method: 'get_user_info',
      params: [ username ],
    })

    const feed = await instagram.page_generator({
      method: 'get_user_feed',
      params: [ user.pk ],
    })

    printLog(`Username @${user.username}: Fetching feed...`)

    // Phase 2: page

    const items = new Lazy(feed)
      .peek((page, index) =>
        printLog(`My Photos: Page ${index}: Fetched ${page.num_results} items.`),
      )
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .map(page => makeGenerator(page.items))
      .flat()

    // Phase 3: Map each photo into list of likers

    const likers_paged = items
      .filter((item, index) => {
        if (instagram.isStopped) {
          printLog(
            `Skipping ${index} ${instagramUrl(item)} : Request was skipped`,
          )
          return false
        }
        return true
      })
      .peek((item, index) =>
        printLog(`Fetching item ${index}, ${instagramUrl(item)} ... `)
      )
      .map(async item => {
        const feed = await instagram.page_generator({
          method: 'get_media_likers',
          params: [item.id],
        })

        return new Lazy(feed).peek((page, index) =>
          printLog(`Photo ${instagramUrl(item)}: Page ${index}: ${page.user_count} likers`)
        )
      })
      .flat()


    const viewed = likers_paged
      .map((page, index) => {
        const { users } = page

        printLog(`Will watch stories of ${page.user_count} users.`)

        return makeGenerator(users)
      })
      .flat()
      .filter(user => !!user)
      .peek(user => printLog(`User @${user.username}`))
      .map(async user => await instagram.request({
        method: 'get_user_reel',
        params: [ user.pk ],
      }))
      .peek(reel => printLog(` has ${reel.items.length} stories`, false))
      .map(reel => {
        const unseen = reel.items.filter(item => item.taken_at > reel.seen)
        console.log('unseen', unseen)
        printLog(`, unseen = ${unseen.length}`, false)
        return unseen
      })
      .filter(items => items.length)
      .map(async (items, index) => {
        console.log(`${index} reel`, items)

        try {
          const response = await instagram.request({
            method: 'see_reels',
            params: [ items ],
          })
          console.log('response', response)

          return response
        } catch (err) {
          console.log('error', err)
          printLog(`, error: ${err.error}`, false)

          if (err.error === 'Request failed with status code 429') {
            printLog(` (Sleeping...`, false)
            const sec = await progressive_sleep()
            printLog(` ${sec} sec)`, false)
          }

          return { status: 'error' }
        }

      })
      .peek(res => printLog(`, seen = ${res.status}`, false))

    const results = await viewed.unwrap()

    printLog(`FINISHED,
      Total requests: ${results.length},
      Success: ${results.filter(item => item.status == 'ok').length} items,
      Errors: ${results.filter(item => item.status == 'error').length} items`)
    return results
  },
}

export default _script_look_stories
