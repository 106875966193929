import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter, BrowserRouter } from 'react-router-dom'
import { connect, Provider } from 'react-redux'
import isMobile from 'is-mobile'
import Honeybadger from 'honeybadger-js'
import OnMobileModal from './components/markup/OnMobileModal'
import { CONNECTION } from './constants'

import Router from './router'

import instagram from './old/instagram_connector'

import { shouldCheckLicense } from './license'

import store from './redux/store'
import {
  activateLicense,
  updateConnectionStatus,
  updateInstagramServiceStatus,
  showLoader,
  hideLoader,
  setUser,
  sendMetrikaEvent,
  showErrorMessage,
  printLog,
  updateStats,
  updateConfig,
} from './redux/actions'

import { connectExtension, fetchStats, sendConfig } from './services'

import { Wrapper, ScrollToTop, LogoutModal } from './components/markup'

class __App extends React.Component {
  componentDidMount() {
    this.props.showLoader()

    if (shouldCheckLicense()) {
      const { isLicenseValid } = this.props

      this.props.printLog(`License: ${isLicenseValid ? 'Valid' : 'Invalid'}`)

      if (!isLicenseValid) {
        const code = window.prompt('Trial is over! Please provide verification code:')

        this.props.activateLicense(code)
      }
    }


    connectExtension()
      .then(({ user, status, error }) => {

        if (shouldCheckLicense()) {
          const { isLicenseValid } = this.props

          if (!isLicenseValid) {
            this.props.updateConnectionStatus(CONNECTION.LICENSE_INVALID)
            return
          }
        }

        this.props.updateConnectionStatus(status)

        this.props.printLog(`App loaded ${new Date()}`)
        this.props.printLog(status)

        this.props.sendMetrikaEvent(`app-loaded`, { status, timestamp: Date.now() })

        if (status === CONNECTION.LOGGED_IN) {
          this.props.sendMetrikaEvent(`app-loaded-logged-in`, { timestamp: Date.now() })
        }

        if (user) {
          this.props.setUser(user)
        } else if (error) {
          this.props.showErrorMessage(error)
        }

        fetchStats()
          .then(stats => this.props.updateStats(stats))
          .catch(err => console.error(err))

        sendConfig()
          .then(config => this.props.updateConfig(config))
          .catch(err => console.error('Cant update config, update extension', err))

        // TODO: HACK
        setInterval(() => {
          this.props.updateInstagramServiceStatus(instagram)
        }, 500)
      })
      .finally(() => this.props.hideLoader())
  }

  render() {
    return (
      <React.Fragment>
        {isMobile() && <OnMobileModal />}
        <Wrapper>
          <Router />
        </Wrapper>
        <ScrollToTop />
        <LogoutModal />
      </React.Fragment>
    )
  }
}

export const App = withRouter(
  connect(
    store => ({
      isLicenseValid: store.isLicenseValid,
    }),
    {
      activateLicense,
      updateConnectionStatus,
      updateInstagramServiceStatus,
      showLoader,
      hideLoader,
      setUser,
      sendMetrikaEvent,
      showErrorMessage,
      printLog,
      updateStats,
      updateConfig,
    },
  )(__App),
)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

Honeybadger.configure({
  apiKey: '682efcf4',
  environment: 'production'
});

export default App
