import Lazy, { LazyError } from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import {
  get_random,
  getCSV,
  download,
  download_array,
  instagramUrl,
  getURL,
  randomTimeout,
  sleep,
  skip,
} from './util'

const populateEmail = user => {
  const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g

  const [email, ...others] = Object.values(user)
    .filter(value => typeof value === 'string')
    .flatMap(value => value.match(emailRegexp) || [])
  console.log('found emails (using only first):', [email, ...others])

  return {
    ...user,
    email,
  }
}

export const load_emails = {
  name: "Download followers ' emails",
  description: `
    You input @username, we load all the emails of the @username's followers

    Start From #N means if you want to skip first (N-1) users, and start from Nth

    You can stop script at any time, wait a bit and it should load a csv with all the loaded emails yet
  `,
  params: [
    {
      name: 'username',
      type: 'text',
      prefix: '@',
      labelText: 'Username',
      defaultValue: 'mipt.ru',
    },
    {
      name: 'shouldLoadFullName',
      type: 'checkbox',
      prefix: '',
      labelText: 'Download full name',
      defaultValue: 'true',
    },
    {
      name: 'shouldLoadBio',
      type: 'checkbox',
      prefix: '',
      labelText: 'Download bio',
      defaultValue: false,
    },
    {
      name: 'startFrom',
      type: 'text',
      prefix: '',
      labelText: 'Start From #',
      defaultValue: 0,
    },
  ],
  run: async (
    { username, shouldLoadFullName, shouldLoadBio, startFrom } = {},
    printLog = console.log,
  ) => {
    const {
      user: { pk },
    } = await instagram.request(
      { method: 'get_user_info', params: [username] },
      true,
    )

    if (!pk || isNaN(pk)) throw new Error(`No user id: ${pk}`)

    // Phase 1: set up feed generator
    const follower_list = instagram.page_generator({
      method: 'get_user_followers',
      params: [pk, ''],
    })

    printLog(`Will start from User #${startFrom}`)

    // Phase 2: pages to list
    const users = new Lazy(follower_list)
      .peek((page, index) =>
        printLog(
          `Batch ${index} of followers for @${username} loaded: ${page.users.length}`,
        ),
      )
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .map(page => makeGenerator(page.users))
      .flat()

    const followers_container = users
      .filter(skip(() => instagram.isStopped, printLog))
      .peek((user, index) => printLog(`User #${index}: @${user.username}: `))
      .filter((_, index) => index >= startFrom)
      .map(user =>
        instagram
          .request({ method: 'get_user_info', params: [user.pk] })
          .then(({ user }) => user),
      )
      .peek(user => printLog(`ok`, false))
      .map(user => populateEmail(user))
      .map(({ pk, username, full_name, email, bio }) => {
        const data = { pk, username, email }

        if (shouldLoadFullName) data.full_name = full_name
        if (shouldLoadBio) data.bio = bio

        return data
      })
      .peek(user => printLog(`, email: ${user.email || 'none (skip)'}`, false))
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .filter(user => !!user.email)
      .peek(user => console.log('user', user))

    const followers = await followers_container.unwrap()

    printLog(`Followers for @${username} loaded: ${followers.length}`)
    printLog(`You can access them in window.followers or download using`)
    printLog(`\t\tdownloadCSV()`)
    printLog(`or`)
    printLog(`\t\tdownload('followers_${username}.csv', getCSV(followers))`)

    window.followers = followers
    window.downloadCSV = () => {
      download_array(followers, `followers_${username}`)
    }

    downloadCSV()

    try {
      localStorage.setItem(`followers_${username}`, JSON.stringify(followers))
    } catch (err) {
      printLog(
        `You can ignore this error: Cant save to localStorage: file too big.`,
      )
    }
  },
}

export default load_emails
