import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button } from '../components/dumb/Button'
import { Card, CardHeader, CardBody } from '../components/markup/Card'
import { message } from '../constants'

import { showLoader, hideLoader, printLog } from '../redux/actions'

import { followList } from '../old/likeItems'

@connect(
  ({ stats }) => ({ stats }),
  { showLoader, hideLoader, printLog },
)
class SupportUsPage extends React.Component {
  followUs = async () => {
    try {
      this.props.showLoader()

      const users = [
        {
          pk: 352300017,
          username: 'danokhlopkov',
        },
        {
          pk: 45786877,
          username: 'caffeinum',
        },
      ]

      followList(users, users.length, this.props.printLog)
    } catch (err) {
      console.error(err)
      this.props.printLog(`Error: ${err.message}`)
      alert(err.message)
    } finally {
      this.props.hideLoader()
    }
  }

  likeUs = async () => {
    try {
      this.props.showLoader()

      likePhotosByUsername('danokhlopkov', 10, this.props.printLog)
      // TODO: like all of us please!
    } catch (err) {
      console.error(err)
      this.props.printLog(`Error: ${err.message}`)
      alert(err.message)
    } finally {
      this.props.hideLoader()
    }
  }

  render() {
    return (
      <div className="container-fluid">
        {this.props.heading && (
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            {/* <!-- Page Heading  --> */}
            <h1 className="h3 mb-0 text-gray-800">Support Us</h1>
          </div>
        )}

        {/* <!-- Content Row  --> */}
        <div className="row">
          <div className="col-lg-12">
            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Donate! &nbsp;
                <i className="fas fa-donate"></i>
              </CardHeader>
              <CardBody>
                <p>{message.PRO_HEADER}</p>
                <p>{message.PRO_TEXT.replace(/<br>/g, '')}</p>

                <div className="d-flex align-items-center justify-content-between">
                  <div className="">
                    <a
                      className="btn btn-lg btn-success"
                      href="https://paypal.me/okhlopkov/10"
                      target="_blank"
                    >
                      <span className="text">Paypal: 5$/month</span>
                    </a>
                  </div>

                  <div className="">
                    <a
                      className="btn btn-lg btn-primary"
                      href="https://www.patreon.com/join/morejust"
                      target="_blank"
                    >
                      Patreon
                    </a>
                  </div>

                  <div className="">
                    <a
                      className="btn btn-lg btn-primary"
                      href="https://buymeacoff.ee/okhlopkov"
                      target="_blank"
                    >
                      <span className="text">Buy a coffee</span>
                    </a>
                  </div>

                  <div className="">
                    <a
                      className="btn btn-lg btn-primary"
                      href="https://liberapay.com/caffeinum/donate"
                      target="_blank"
                    >
                      Liberapay
                    </a>
                  </div>

                  <div className="">
                    <Link
                      className="btn btn-lg btn-primary"
                      to="/script/like_by_hashtag"
                    >
                      <span className="text">Check out PRO</span>
                      <i className="fas fa-star"></i>
                    </Link>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-6">
            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Use this website to
              </CardHeader>
              <CardBody>
                <p>
                  We would be very happy if you follow us on Instagram!
                  Moreover, this is a good place to test our website.
                </p>

                <Button
                  className="btn-warning btn-icon-split"
                  onClick={this.followUs}
                  ym={`supportUs-follow`}
                >
                  <span className="icon text-white-50">
                    <i className="fas fa-user-friends"></i>
                  </span>
                  <span className="text">Follow Us on Instagram</span>
                </Button>

                <div className="my-2"></div>

                <Button
                  className="btn-warning btn-icon-split"
                  onClick={this.likeUs}
                  ym={`supportUs-like`}
                >
                  <span className="icon text-white-50">
                    <i className="fas fa-heart"></i>
                  </span>
                  <span className="text">Like Us on Instagram</span>
                </Button>
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-6">
            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Other links
              </CardHeader>
              <CardBody>
                <p>
                  Just other links that you'd like to like, follow, donate,
                  inspect, love, hate, ... you name it. Enjoy!
                </p>
                <a
                  href="https://twitter.com/caffeinum"
                  className="btn btn-warning btn-icon-split"
                  target="_blank"
                >
                  <span className="icon text-white-50">
                    <i className="fab fa-twitter"></i>
                  </span>
                  <span className="text">Follow us on Twitter</span>
                </a>

                <div className="my-2"></div>

                <a
                  href="https://buymeacoff.ee/okhlopkov"
                  className="btn btn-primary btn-icon-split"
                  target="_blank"
                >
                  <span className="icon text-white-50">
                    <i className="fas fa-coffee"></i>
                  </span>
                  <span className="text">Buy us a coffee</span>
                </a>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default SupportUsPage
