import Lazy from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import {
  getCSV,
  download,
  download_array,
  getURL,
  randomTimeout,
  sleep,
  skip,
} from './util'


export const find_nondual_followings = {
  name: 'Find and show users who doesnt follow back (non-mutual-followers)',
  description: `WARNING: DOESNT WORK AS FOR NOW, INSTAGRAM HAS CHANGED SOMETHING`,
  params: [
    {
      name: 'doUnfollow',
      type: 'checkbox',
      prefix: '',
      labelText: 'Unfollow them?',
      defaultValue: true,
    },
  ],
  run: async ({ doUnfollow = false }, printLog = console.log) => {
    const {
      user: { pk },
    } = await instagram.request({ method: 'check_login', params: [] })

    if (!pk || isNaN(pk)) throw new Error(`No user id: ${pk}`)

    if (
      doUnfollow &&
      !confirm(`You sure you want to unfollow automatically?`)
    ) {
      doUnfollow = false
    }

    // Phase 1: set up generator
    const following_list = instagram.page_generator({
      method: 'get_user_followings',
      params: [pk],
    })

    // Phase 2: paging
    const followings = new Lazy(following_list)
      .peek((page, index) =>
        printLog(`Batch ${index} of followings loaded: ${page.users.length}`),
      )
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .map(page => makeGenerator(page.users))
      .flat()

    // Phase 3: search each in followers
    const follows = followings
      .filter(skip(() => instagram.isStopped, printLog))
      .peek(user => printLog(`user: @${user.username}: `))
      .map(user =>
        instagram
          .request({ method: 'user_friendship', params: [user.pk] })
          .then(info => ({ friendship: info, ...user })),
      )
      .peek(user =>
        printLog(
          user.friendship.followed_by ? 'follows you' : 'doesnt follow you',
          false,
        ),
      )
      .peek(user => console.log('user', user))
      .map(async user => {
        if (
          doUnfollow &&
          user &&
          user.friendship &&
          !user.friendship.followed_by
        ) {
          const sec = randomTimeout()
          printLog(`Sleeping ${sec.toFixed(1)} sec`)
          await sleep(sec * 1000)

          printLog(`Unfollow ${getURL(user)}`)
          await instagram.request({ method: 'unfollow', params: [user.pk] })
        }
      })
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))

    const full_info = await follows.unwrap()

    window.full_info = full_info

    const non_dual = full_info.filter(
      user => user && user.friendship && !user.friendship.followed_by,
    )

    window.non_dual = non_dual

    printLog(`Loaded!`)
    printLog(`You follow ${full_info.length} people.`)
    printLog(`${non_dual.length} of them dont follow you back.`)
    printLog(`Here they are:`)
    printLog(``)

    non_dual.map(user =>
      printLog(`@${user.username}: https://instagram.com/${user.username}`),
    )

    if (doUnfollow) {
      const uf = new Lazy.from(
        non_dual.map(({ pk, username }) => ({ pk, username })),
      )
        .peek(user => printLog(`Unfollow ${getURL(user)}`))
        .map(user =>
          instagram.request({ method: 'unfollow', params: [user.pk] }),
        )
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))

      return uf.unwrap()
    }

    return non_dual
  },
}


export default find_nondual_followings
