import { createStore, combineReducers } from 'redux'
import Honeybadger from 'honeybadger-js'
import { CONNECTION_MESSAGES, CONNECTION, AD_URL } from '../constants'

import { checkTrial, checkLicenseCode } from '../license'

const { Chatra } = window

let installedAt
let verificationCode
let isCodeValid
let isTrialValid

try {
  installedAt = parseInt(localStorage.getItem('installedAt')) || Date.now()
  verificationCode = localStorage.getItem('verificationCode')

  isCodeValid = checkLicenseCode(verificationCode)
  isTrialValid = checkTrial(installedAt)
} catch (err) {
  console.error('Error while checking license', err)
}

const initialState = {
  isLoading: false,
  installedAt,
  verificationCode,
  isLicenseValid: isTrialValid || isCodeValid,
  isCodeValid,
  isTrialValid,
  notifyWhenQueueFinished: false,
  instagram: {
    isStopped: true,
  },
  connection: {
    status: CONNECTION.UNKNOWN,
    description: '',
  },
  subscriptions: [],
  log: JSON.parse(localStorage.getItem('log')) || [],
  user: {},
  error: '',
  stats: {
    follower_count: null,
    following_count: null,
    likes: null,
    follows: null,
    full: {},
  },
  config: {},
  IS_AD_ENABLED: true,
}

const reducer = (state = initialState, action) => {
  if (action.type !== 'INSTAGRAM') {
    console.log('action', action)
  }

  if (action.type === 'CLEAR_LOG') {
    localStorage.setItem('log', JSON.stringify([]))

    return {
      ...state,
      log: [],
    }
  }

  if (action.type === 'PRINT_LOG') {
    const { line, newLine } = action.payload

    const log = newLine ? [...state.log, `<br>`, line] : [...state.log, line]

    localStorage.setItem('log', JSON.stringify(log))

    return {
      ...state,
      log,
    }
  }

  if (action.type === 'OPEN_AD') {
    const { IS_AD_ENABLED, config } = state

    // if (!config.JOINED_FAMILY && IS_AD_ENABLED) {
    if (!config.JOINED_FAMILY && IS_AD_ENABLED) {
      // if (IS_AD_ENABLED) {
      window.open(AD_URL)
    }
  }

  if (action.type === 'INSTAGRAM') {
    const { isStopped } = action.payload.instagram

    if (!state.isStopped && isStopped) {
      if (state.notifyWhenQueueFinished) {
        alert(`Queue finished!`)

        return {
          ...state,
          notifyWhenQueueFinished: false,
          isStopped,
        }
      }
    }
  }

  if (action.type === 'SET_USER') {
    const { user = {} } = action.payload

    const { IS_AD_ENABLED, config } = state

    Honeybadger.setContext({
      user_id: user.pk,
      user_username: user.username,
      user_email: user.public_email,
    })

    const data = {
      notes: `@${user.username}

on: GramUp Dashboard
pk: ${user.pk}
email: ${user.public_email}
config: ${JSON.stringify(config, null, 4)}`,
      // custom properties
      user_id: user.pk,
      user_username: user.username,

      config: JSON.stringify(config, null, 4),
    }

    Chatra.updateIntegrationData(data)
  }

  if (action.type === 'ACTIVATE_LICENSE') {
    const { code } = action.payload
    const { verificationCode, installedAt } = state

    let isLicenseValid = false
    const isCodeValid = false
    const _code = code || verificationCode

    console.log('Installed at', new Date(installedAt))
    localStorage.setItem('installedAt', installedAt)

    const isTrialValid = checkTrial(installedAt) // Date.now() < installedAt + 1000 // 1000 * 60 * 60 * 24 * 7

    if (isTrialValid) {
      isLicenseValid = true
    } else {
      const isCodeValid = checkLicenseCode(_code)

      if (isCodeValid) {
        localStorage.setItem('verificationCode', _code)
      } else {
        localStorage.removeItem('verificationCode')
      }

      isLicenseValid = isCodeValid

      if (!isLicenseValid) {
        console.error('License invalid')

        setTimeout(() => {
          console.error('Extension disconnected')
          instagram.block()
        }, 1000)

        alert(
          'License expired or invalid! Please update your license to use app',
        )
      }
    }

    return {
      ...state,
      isCodeValid,
      isTrialValid,
      isLicenseValid,
      verificationCode: _code,
    }
  }

  return {
    ...state,
    ...action.payload,
  }
}

const store = createStore(reducer)

const { getState } = store

export default store
