import React from 'react'
import { connect } from 'react-redux'

import {
  showLoader,
  hideLoader,
  printLog,
  clearLog,
  updateStats,
} from '../redux/actions'

import { userUrl } from '../scripts/util'
import { fetchStats } from '../services'

import Plot from '../components/dumb/Plot'
import { Button, LinkButton, InlineButton } from '../components/dumb/Button'
import { LogCard } from '../components/markup'

import { Card, CardHeader, CardBody } from '../components/markup/Card'

class DashboardPage extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.stats &&
      this.props.stats.full &&
      this.props.stats.full.hasOwnProperty('current') &&
      !this.props.stats.family
    ) {
      fetch(
        `https://flask-now-https-proxy.okhlopkov.now.sh/v1/family/user_id/${this.props.stats.full.current.pk}`,
      )
        .then(r => r.json())
        .then(r =>
          this.props.updateStats({
            ...this.props.stats,
            family: r.family,
          }),
        )
    }
  }

  updateStats = () => {
    fetchStats()
      .then(stats => this.props.updateStats(stats))
      .then(() => alert(`Updated stats`))
  }

  renderFamilyCard = () => {
    return (
      <Card
        className="shadow mb-4"
        style={{
          height: 'calc(100% - 1.5rem)',
        }}
      >
        <CardHeader className="py-3">
          GramUp! Family
        </CardHeader>
        <CardBody
          className="display-flex align-items-center justify-content-center text-center"
          style={{
            // display: 'flex',
            // textAlign: 'center',
            // justifyContent: 'center',
            // alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div className="mb-2" style={{ fontSize: '2rem' }}>
            <span className="font-weight-bold">
              GramUp! Family
            </span>
            {' '}has moved to{' '}
            <span className="font-weight-bold">
              LikeUp.me
            </span>
          </div>
          <p>
            <b>LikeUp.me</b> is a like exchange community. You will get instant likes when you post, taking a chance to boost&nbsp;your photo <b>into Explore</b>.
          </p>
          <p>
            LikeUp is an upgraded version of GramUp! Family. You can still use GramUp extension with LikeUp: to see your stats, open LikeUp Dashboard.
          </p>
          <p>
            With <b>free tier</b>, you will get up to 30 likes to each post. It's FREE forever. Join <b>LikeUp Unlimited</b> to get even more likes!
          </p>
          <div className="mt-2">
            <InlineButton
              className="btn btn-lg btn-danger"
              href="https://likeup.me/?utm_source=gramup-dashboard&ref=gramup-dashboard"
              target="_blank"
            >
              Try Unlimited
            </InlineButton>

            <InlineButton
              className="btn btn-lg btn-outline-primary ml-1"
              href="https://family-dashboard.caffeinum.now.sh/?utm_source=gramup-dashboard&ref=gramup-dashboard"
              target="_blank"
            >
              Use LikeUp Free
            </InlineButton>
          </div>
        </CardBody>
      </Card>
    )
  }

  render() {
    const { stats, config } = this.props

    if (!stats || !stats.full)
      return <div className="container-fluid"> Loading... </div>

    const JOINED_FAMILY =
      config && config.hasOwnProperty('JOINED_FAMILY')
        ? this.props.config.JOINED_FAMILY
        : false

    const followersData = stats.full.follower_count
      ? stats.full.follower_count.filter(d => d.pk === this.props.user.pk)
      : []
    const likesData = stats.full.average_like_count
      ? stats.full.average_like_count.filter(d => d.pk === this.props.user.pk)
      : []

    const likesChartColor = '#e74a3b'
    const followeChartColor = '#3977af'

    return (
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">

          {/* <!-- Page Heading  --> */}
          <h1 className="h3 mb-0 text-gray-800"> Dashboard </h1>
          <Button
            className="btn btn-warning btn-icon-split"
            onClick={this.updateStats}
            ym={`dashboard-update`}
          >
            <span className="icon text-white">
              <i className="fas fa-sync"> </i>
            </span>
            <span className="text"> Update Stats </span>
          </Button>
          {!JOINED_FAMILY && (
            <LinkButton
              className="btn btn-danger btn-icon-split"
              ym={`open-family-join-page`}
              to="/family"
            >
              <span className="icon text-white">
                <i className="fas fa-check"> </i>
              </span>
              <span className="text text-white"> Gram Up!Family </span>
            </LinkButton>
          )}
        </div>
        {/* <!-- Content Row  --> */}
        <div className="row">

          {/* <!-- Total Liked  --> */}
          <div className="col-xl-3 col-md-3 mb-4">
            <Card className="border-left-primary shadow h-100 py-2">
              <CardBody>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Total Liked
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">

                      {stats.likes ? stats.likes : 'Unknown'}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-heart fa-2x text-gray-300"> </i>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          {/* <!-- Total Followed  --> */}
          <div className="col-xl-3 col-md-3 mb-4">
            <Card className="border-left-success shadow h-100 py-2">
              <CardBody>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Total Followed
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">

                      {stats.follows !== null ? stats.follows : 'Unknown'}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-user-friends fa-2x text-gray-300"> </i>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          {/* <!-- Total Followed  --> */}
          <div className="col-xl-3 col-md-3 mb-4">
            <Card className="border-left-info shadow h-100 py-2">
              <CardBody>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Followers
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">

                      {stats.follower_count
                        ? stats.follower_count
                        : 'Unknown'}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-user-friends fa-2x text-gray-300"> </i>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          {/* <!-- Total Followed  --> */}
          <div className="col-xl-3 col-md-3 mb-4">
            <Card className="border-left-warning shadow h-100 py-2">
              <CardBody>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Average Likes
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">

                      {stats.average_like_count
                        ? stats.average_like_count
                        : 'Unknown'}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-user-friends fa-2x text-gray-300"> </i>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        {/* <!-- Content Row  --> */}
        <div className="row">

          {/* <!-- Area Chart  --> */}
          <div className="col-xl-6 col-lg-6">
            <Card className="shadow mb-4">
              <CardHeader className="py-3 d-flex flex-row align-items-center justify-content-between">
                Followers and Average likes
                <a href={userUrl(this.props.user)} target="_blank">
                  @ {this.props.user.username}
                </a>
              </CardHeader>
              <CardBody>

                {!followersData && !likesData && 'No data'}
                {(followersData || likesData) && (
                  <div
                    className="chart-area"
                    style={{
                      height: '500px',
                    }}
                  >
                    <Plot
                      debug={true}
                      data={[
                        {
                          type: 'scatter',
                          name: 'Followers',
                          x: followersData.map(d => new Date(d.savedAt)),
                          y: followersData.map(d => d.followers),
                        },
                        {
                          type: 'scatter',
                          name: 'Average likes',
                          x: likesData.map(d => new Date(d.savedAt)),
                          y: likesData.map(d => d.likes),
                          yaxis: 'y2',
                        },
                      ]}
                      layout={{
                        colorway: [followeChartColor, likesChartColor],
                        autosize: true,
                        showlegend: false,
                        margin: {
                          l: 50,
                          r: 50,
                          b: 30,
                          t: 30,
                        },
                        yaxis: {
                          title: 'Followers',
                          titlefont: {
                            color: followeChartColor,
                          },
                          tickfont: {
                            color: followeChartColor,
                          },
                        },
                        yaxis2: {
                          title: 'Average likes',
                          titlefont: {
                            color: likesChartColor,
                          },
                          tickfont: {
                            color: likesChartColor,
                          },
                          overlaying: 'y',
                          side: 'right',
                        },
                      }}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </div>

          <div className="col-xl-6 col-lg-6">
            {this.renderFamilyCard()}
          </div>

          <div className="col-xl-12 col-lg-12">
            <LogCard
              log={this.props.log.slice(-300)}
              clearLog={this.props.clearLog}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ user, isLoading, log, config, connection, stats }) => ({
    user,
    config,
    isLoading,
    log,
    connection,
    stats,
  }),
  {
    showLoader,
    hideLoader,
    printLog,
    clearLog,
    updateStats,
  },
)(DashboardPage)
