import React from 'react'
import { Link } from 'react-router-dom'
import icon from '../../../img/icon.png'

export default () => (
  <ul
    className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    {/* Sidebar */}

    {/* <!-- Sidebar - Brand  --> */}
    <Link
      className="sidebar-brand d-flex align-items-center justify-content-center"
      to="/"
    >
      <div className="sidebar-brand-icon">
        <img src={icon} />
      </div>
      <div className="sidebar-brand-text mx-3">Gram Up Yourself!</div>
    </Link>

    {/* <!-- Divider  --> */}
    <hr className="sidebar-divider my-0" />

    {/* <!-- Nav Item - Dashboard  --> */}
    <li className="nav-item active">
      <Link className="nav-link" to="/">
        <i className="fas fa-fw fa-tachometer-alt" />
        <span>Dashboard</span>
      </Link>
    </li>

    <li className="nav-item">
      <a className="nav-link" href="https://dm.gramup.me/" target="_blank">
        <i className="fas fa-fw fa-envelope" />
        <span>Direct Messages</span>
      </a>
    </li>

    <li className="nav-item">
      <a
        className="nav-link"
        href="https://github.com/caffeinum/GramUp/wiki/FAQ"
        target="_blank"
      >
        <i className="fas fa-fw fa-sm fa-question" />
        <span>FAQ</span>
      </a>
    </li>

    <li className="nav-item">
      <a
        className="nav-link"
        href="https://patreon.com/morejust"
        target="_blank"
        style={{ background: '#ff0088' }}
      >
        <i className="fas fa-fw fa-user-shield text-white" />
        <span>Become PRO</span>
      </a>
    </li>

    {/* <!-- Divider  --> */}
    <hr className="sidebar-divider" />

    {/* <!-- Heading  --> */}
    <div className="sidebar-heading">Actions</div>

    {/* <!-- Nav Item - Like Collapse Menu  --> */}
    <li className="nav-item">
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseLike"
        aria-expanded="true"
        aria-controls="collapseLike"
      >
        <i className="fas fa-fw fa-heart" />
        <span>Like</span>
      </a>
      <div
        id="collapseLike"
        className="collapse"
        aria-labelledby="headingLike"
        data-parent="#accordionSidebar"
      >
        <div className="bg-white py-2 collapse-inner rounded">
          {/* <!-- <h6 className="collapse-header">Custom Components:</h6>  --> */}
          <Link className="collapse-item" to="/script/like_user">
            User Media
          </Link>
          <Link className="collapse-item" to="/script/like_followers">
            Followers
          </Link>
          <Link className="collapse-item" to="/script/like_my_feed">
            Your Feed
          </Link>
          <Link className="collapse-item" to="/script/like_location">
            Location
          </Link>
          <Link className="collapse-item" to="/script/like_by_hashtag">
            Like Hashtag
            <i className="fas fa-fw fa-star" />
          </Link>
          <Link className="collapse-item" to="/script/like_user_fans">
            User Fans
            <i className="fas fa-fw fa-star" />
          </Link>
          <Link className="collapse-item" to="/script/like_hashtag_fans">
            Hashtag Fans
            <i className="fas fa-fw fa-star" />
          </Link>

          {/* <Link className="collapse-item" to="/like">All</Link> */}
          {/* <a className="collapse-item" href="like/hashtag.html">Hashtag</a> */}
          {/* <a className="collapse-item" href="like/user-medias.html">User Medias</a> */}
        </div>
      </div>
    </li>

    {/* <!-- Nav Item - Follow Collapse Menu  --> */}
    <li className="nav-item">
      <Link className="nav-link" to="/follow">
        <i className="fas fa-fw fa-user-friends" />
        <span>Follow</span>
      </Link>
    </li>

    {/* <!-- Nav Item - Follow Collapse Menu  --> */}
    <li className="nav-item">
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseFollow"
        aria-expanded="true"
        aria-controls="collapseFollow"
      >
        <i className="fas fa-fw fa-user-friends" />
        <span>Follow Users</span>
      </a>

      <div
        id="collapseFollow"
        className="collapse"
        aria-labelledby="headingFollow"
        data-parent="#accordionSidebar"
      >
        <div className="bg-white py-2 collapse-inner rounded">
          {/* <!-- <h6 className="collapse-header">Custom Utilities:</h6>  --> */}
          <Link className="collapse-item" to="/follow/followers">
            Followers
          </Link>
          <Link className="collapse-item" to="/follow/followings">
            Followees
          </Link>
          <Link className="collapse-item" to="/script/follow_by_hashtag">
            Hashtag Authors
          </Link>
        </div>
      </div>
    </li>

    {/* <!-- Nav Item - Unfollow Collapse Menu  --> */}
    <li className="nav-item">
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseUnfollow"
        aria-expanded="true"
        aria-controls="collapseUnfollow"
      >
        <i className="fas fa-fw fa-user-times" />
        <span>Unfollow</span>
      </a>
      <div
        id="collapseUnfollow"
        className="collapse"
        aria-labelledby="headingUnollow"
        data-parent="#accordionSidebar"
      >
        <div className="bg-white py-2 collapse-inner rounded">
          <Link className="collapse-item" to="/script/unfollow_everyone">
            Everyone
          </Link>
        </div>
      </div>
    </li>

    {/* <!-- Nav Item - Download Collapse Menu  --> */}
    <li className="nav-item">
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseDownload"
        aria-expanded="true"
        aria-controls="collapseDownload"
      >
        <i className="fas fa-fw fa-file-download" />
        <span>Download</span>
      </a>
      <div
        id="collapseDownload"
        className="collapse"
        aria-labelledby="headingDownload"
        data-parent="#accordionSidebar"
      >
        <div className="bg-white py-2 collapse-inner rounded">
          {/* <!-- <h6 className="collapse-header">Custom Utilities:</h6>  --> */}
          <Link className="collapse-item" to="/script/load_followers">
            Followers
          </Link>
          <Link className="collapse-item" to="/script/load_stories">
            Stories
          </Link>
          <Link className="collapse-item" to="/script/load_emails">
            Emails
          </Link>
        </div>
      </div>
    </li>

    {/* <!-- Nav Item - Comment Collapse Menu  --> */}
    <li className="nav-item">
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseComment"
        aria-expanded="true"
        aria-controls="collapseDownload"
      >
        <i className="fas fa-fw fa-file-download" />
        <span>Comment</span>
      </a>
      <div
        id="collapseComment"
        className="collapse"
        aria-labelledby="headingDownload"
        data-parent="#accordionSidebar"
      >
        <div className="bg-white py-2 collapse-inner rounded">
          {/* <!-- <h6 className="collapse-header">Custom Utilities:</h6>  --> */}
          <Link className="collapse-item" to="/script/comment_by_hashtag">
            Hashtag
          </Link>
          <Link className="collapse-item" to="/script/comment_by_user">
            User
          </Link>
          <Link className="collapse-item" to="/script/comment_by_post">
            Posts
          </Link>
        </div>
      </div>
    </li>

    <hr className="sidebar-divider" />

    <div className="sidebar-heading">Schedule</div>

    <li className="nav-item">
      <Link className="nav-link" to="/script/stop?BETA_TEST=true">
        <i className="fas fa-window-close" />
        <span>Schedule STOP</span>
      </Link>
    </li>

    {/* <!-- Divider  --> */}
    <hr className="sidebar-divider" />

    {/* <!-- Heading  --> */}
    <div className="sidebar-heading">Useful links</div>

    {/* <!-- Nav Item -   --> */}
    <li className="nav-item">
      <Link className="nav-link" to="/support-us">
        <i className="fas fa-fw fa-grin-hearts" />
        <span>Support us</span>
      </Link>
    </li>

    {/* <!-- Nav Item -   --> */}
    <li className="nav-item">
      <a
        className="nav-link"
        href="https://chrome.google.com/webstore/detail/gram-up/njonkbhnmmjgancfbncekpgkmidhbbpo"
        target="_blank"
      >
        <i className="fab fa-fw fa-chrome" />
        <span>Rate extension</span>
      </a>
    </li>

    {/* <!-- Nav Item -   --> */}
    <li className="nav-item">
      <a
        className="nav-link"
        href="https://t.me/instabotproject"
        target="_blank"
      >
        <i className="fab fa-fw fa-telegram" />
        <span>Telegram</span>
      </a>
    </li>

    {/* <!-- Nav Item -   --> */}
    <li className="nav-item">
      <a
        className="nav-link"
        href="https://github.com/instagrambot/gramup"
        target="_blank"
      >
        <i className="fab fa-fw fa-github" />
        <span>GitHub</span>
      </a>
    </li>

    {/* <!-- Nav Item -   --> */}
    <li className="nav-item">
      <a
        className="nav-link"
        href="https://paypal.me/okhlopkov/10"
        target="_blank"
      >
        <i className="fas fa-fw fa-donate" />
        <span>Donate</span>
      </a>
    </li>

    {/* <!-- Nav Item -   --> */}
    {/* <li className="nav-item">
      <a className="nav-link" href="https://github.com/instagrambot/web#installing-extension" target="_blank">
        <i className="fas fa-fw fa-question"></i>
        <span>FAQ</span></a>
    </li> */}

    {/* <!-- Divider  --> */}
    <hr className="sidebar-divider d-none d-md-block" />

    <li className="nav-item">
      <Link className="nav-link" to="/logs">
        <i className="fas fa-fw fa-file-alt" />
        <span>Logs</span>
      </Link>
    </li>

    <li className="nav-item">
      <Link className="nav-link" to="/legal">
        <i className="fas fa-fw fa-file-signature" />
        <span>Legal</span>
      </Link>
    </li>

    {/* <!-- Sidebar Toggler (Sidebar)  --> */}
    {/* <div className="text-center d-none d-md-inline">
      <button className="rounded-circle border-0" id="sidebarToggle"></button>
    </div> */}

    {/* <!-- End of Sidebar  --> */}
  </ul>
)
