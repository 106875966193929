import React from 'react'
import { Link } from 'react-router-dom'

const _onClick = (handler, props) => event => {
  event.preventDefault()

  const ymParams = props.ymParams

  if (props.ym) {
    console.log(
      'ym',
      53083903,
      'reachGoal',
      `button-click-${props.ym}`,
      ymParams,
    )

    ym(53083903, 'reachGoal', `button-click-${props.ym}`, ymParams)
  } else {
    const buttonText = event.target.textContent

    const name = String(buttonText)
      .replace(/\s/g, '-')
      .toLowerCase()

    console.log(
      'ym',
      53083903,
      'reachGoal',
      `button-click-${name ? name : 'unknown'}`,
      ymParams,
    )
    ym(
      53083903,
      'reachGoal',
      `button-click-${name ? name : 'unknown'}`,
      ymParams,
    )
  }

  return handler(event)
}

export const InlineButton = props => (
  <a
    href="#"
    className={props.className}
    onClick={props.onClick && _onClick(props.onClick, props)}
    {...props}
  >
    {props.children}
  </a>
)

export const LinkButton = props => (
  <Link
    className={props.className}
    onClick={props.onClick && _onClick(props.onClick, props)}
    {...props}
  >
    {props.children}
  </Link>
)

export const Button = props => (
  <button
    type="button"
    className={`btn ${props.className}`}
    onClick={props.onClick && _onClick(props.onClick, props)}
  >
    {props.children}
  </button>
)
