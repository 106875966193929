import Lazy, { LazyError } from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import {
  get_random,
  getCSV,
  download,
  download_array,
  instagramUrl,
  getURL,
  randomTimeout,
  sleep,
  skip,
} from './util'

const populateEmail = user => {
  const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g

  const [email, ...others] = Object.values(user)
    .filter(value => typeof value == 'string')
    .flatMap(value => value.match(emailRegexp) || [])
  console.log('found emails (using only first):', [email, ...others])

  return {
    ...user,
    email,
  }
}

export const load_followers = {
  name: 'Load full list of user followers',
  params: [
    {
      name: 'username',
      type: 'text',
      prefix: '@',
      labelText: 'Username',
      defaultValue: 'caffeinum',
    },
    {
      name: 'isFullInfo',
      type: 'checkbox',
      prefix: '',
      labelText:
        'Download full profile for each follower, including email, if there is one (takes much longer)',
      defaultValue: false,
    },
    {
      name: 'startFrom',
      type: 'text',
      prefix: '',
      labelText: 'Start From #',
      defaultValue: 0,
    },
    {
      name: 'endAt',
      type: 'text',
      prefix: '',
      labelText: 'End After #',
      defaultValue: Infinity,
    },
    {
      name: 'next_max_id',
      type: 'text',
      prefix: '',
      labelText: 'next_max_id',
      // defaultValue: '',
    },
  ],
  run: async (
    { username, isFullInfo = false, next_max_id, endAt = Infinity },
    printLog = console.log,
    timeout,
  ) => {
    printLog('Set request timeout 1sec')
    _sleep_timeout = 1

    const { user: { pk } } = await instagram.request(
      { method: 'get_user_info', params: [username] }
    )

    printLog('Get user pk = ' + pk)
    
    if (!pk || isNaN(pk)) throw new Error(`No user id: ${pk}`)

    await sleep(1)

    // Phase 1: set up feed generator
    const follower_list = instagram.page_generator({
      method: 'get_user_followers',
      params: [pk, null],
    }, next_max_id)

    // Phase 2: pages to list
    const users = new Lazy(follower_list)
      .peek((page, index) =>
        printLog(
          `Batch ${index} of followers for @${username} loaded: ${page.users.length}\nnext_max_id=${page.next_max_id}`,
        )
      )
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .map(page => makeGenerator(page.users))
      .flat()

    if (!isFullInfo) {
      const followers = await users
        // .filter(skip(() => instagram.isStopped, printLog))
        // .peek((user, index) => printLog(`User #${index}: ${user.pk} @${user.username}`))
        // .filter((_, index) => index >= startFrom)
        // .filter((_, index) => index <= endAt)
        .unwrap()

      printLog(`Followers for @${username} loaded: ${followers.length}`)
      printLog(`You can access them in window.followers or download using`)
      printLog(`\t\tdownloadCSV()`)
      printLog(`or`)
      printLog(`\t\tdownload('followers_${username}.csv', getCSV(followers))`)

      window.followers = followers
      window.downloadCSV = () => {
        download_array(followers, `followers_${username}`, 1000)
      }

      downloadCSV()

      try {
        localStorage.setItem(`followers_${username}`, JSON.stringify(followers))
      } catch (err) {
        printLog(`You can ignore this error: Cant save to localStorage: file too big.`)
      }

      _sleep_timeout = 30

      return
    } else {

      const followers_container = users
        .filter(skip(() => instagram.isStopped, printLog))
        .peek(user => printLog(`user: @${user.username}: `))
        .map(user =>
          instagram
            .request({ method: 'get_user_info', params: [user.pk] })
            .then(({ user }) => user),
        )
        .peek(user => printLog(`ok`, false))
        .map(user => populateEmail(user))
        .peek(user => printLog(`, email: ${user.email || 'none'}`, false))
        .peek(user => console.log('user', user))
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))

      const followers = await followers_container.unwrap()

      printLog(`Followers for @${username} loaded: ${followers.length}`)
      printLog(`You can access them in window.followers or download using`)
      printLog(`\t\tdownloadCSV()`)
      printLog(`or`)
      printLog(`\t\tdownload('followers_${username}.csv', getCSV(followers))`)
      window.followers = followers
      window.downloadCSV = () => {
        download_array(followers, `followers_${username}`)
      }

      downloadCSV()

      try {
        localStorage.setItem(`followers_${username}`, JSON.stringify(followers))
      } catch (err) {
        printLog(`You can ignore this error: Cant save to localStorage: file too big.`)
      }
    }

    _sleep_timeout = 30
  },
}

export default load_followers
