import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { CreateTaskCard } from '../components/markup'

class __ScriptPage extends React.Component {
  render() {
    const scriptName = this.props.match.params.name
    const { BETA_TEST, AUTO_RUN, ...scriptParams } = queryString.parse(this.props.location.search)

    return (
      <div className="container-fluid">
        <CreateTaskCard
          name={scriptName}
          AUTO_RUN={AUTO_RUN}
          BETA_TEST={BETA_TEST}
          defaultParams={scriptParams}
        />
      </div>
    )
  }
}

export default connect(
  null,
  {},
)(__ScriptPage)
