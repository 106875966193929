import React from 'react'
import { Redirect } from 'react-router-dom'
import FollowByListCard from './FollowByListCard'
import FollowByFollowersCard from './FollowByFollowersCard'
import { CreateTaskCard } from '../../components/markup'

class __FollowUserPeersPage extends React.Component {
  state = {
    users: [],
    shouldRedirectToLogs: false,
  }

  handleRedirectToLogs = () => {
    this.setState({ shouldRedirectToLogs: true })
  }

  render() {
    if (this.state.shouldRedirectToLogs) return <Redirect push to="/logs" />

    return (
      <div className="container-fluid">
        <FollowByFollowersCard handleRedirectToLogs={this.handleRedirectToLogs} />

        <FollowByListCard handleRedirectToLogs={this.handleRedirectToLogs} />

        <CreateTaskCard name='follow_by_hashtag' />
      </div>
    )
  }
}

export default __FollowUserPeersPage;
