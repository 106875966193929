import look_stories from './look_stories'
import look_many_stories from './look_many_stories'
import look_stories_user_fans from './look_stories_user_fans'

import load_followers from './load_followers'
import load_followings from './load_followings'
import load_emails from './load_emails'
import load_emails_by_hashtag from './load_emails_by_hashtag'

import unfollow_everyone from './unfollow_everyone'
import find_nondual_followings from './find_nondual_followings'

import comment_by_hashtag from './comment_by_hashtag'
import comment_by_user from './comment_by_user'
import comment_by_post from './comment_by_post'

import schedule from './schedule'
import _scripts from './scripts'

const scripts = {
  look_stories,
  look_many_stories,
  look_stories_user_fans,
  schedule,
  load_followers,
  load_followings,
  load_emails,
  load_emails_by_hashtag,
  unfollow_everyone,
  find_nondual_followings,
  comment_by_hashtag,
  comment_by_user,
  comment_by_post,
  ..._scripts,
}


window.scripts = scripts

export default scripts
