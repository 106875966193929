import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Card, CardHeader, CardBody } from '../components/markup/Card'
import { JoinFamilyButton } from '../components/dumb/JoinFamilyButton'
import { Button } from '../components/dumb/Button'

import {
  maybeOpenAd,
} from '../redux/actions'

export const DailyAdPage = (props) => (
  <div className="container-fluid">
    <h1>Daily Ad</h1>

    <div className="row">

      <div className="col-lg-12">
        <Card className="shadow mb-4">
          <CardHeader className="py-3" id="how">
            Click the Link!
          </CardHeader>
          <CardBody>
            <Button
              className="btn-primary"
              onClick={() => props.maybeOpenAd()}
            >
              Random AD
            </Button>
          </CardBody>
        </Card>
      </div>

    </div>

    <div className="row">

      <div className="col-lg-12">

        <Card className="shadow mb-4">
          <CardHeader className="py-3" id="how">
            Family AD
          </CardHeader>
          <CardBody>

            <JoinFamilyButton>
              Join the Family!
            </JoinFamilyButton>
          </CardBody>
        </Card>

      </div>
    </div>

    <div className="row">

      <div className="col-lg-12">
        <Card className="shadow mb-4">
          <CardHeader className="py-3" id="how">
            Watch me!
          </CardHeader>
          <CardBody>
            <h1>
              YOUR AD CAN BE HERE
            </h1>
          </CardBody>
        </Card>
      </div>

    </div>

  </div>
)

export default connect(null, { maybeOpenAd })(DailyAdPage)
