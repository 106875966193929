export const CONNECTION = Object.freeze({
  UNKNOWN: `Connection status: UNKNOWN`,
  NOT_INSTALLED: `Connection status: NOT_INSTALLED`,
  NOT_LOGGED_IN: `Connection status: NOT_LOGGED_IN`,
  LOGGED_IN: `Connection status: LOGGED_IN`,
  LICENSE_INVALID: `Connection status: LICENSE_INVALID`,
})

export const CONNECTION_MESSAGES = {
  [CONNECTION.UNKNOWN]: `Unknown error`,
  [CONNECTION.NOT_INSTALLED]: `Extension is not installed or not detected`,
  [CONNECTION.NOT_LOGGED_IN]: `Connected to the extension, but it's not logged in. Please login via pressing extension logo`,
  [CONNECTION.LOGGED_IN]: `Website connected to the extension.`,
  [CONNECTION.LICENSE_INVALID]: `License invalid, disconnected`,
}

export const message = {
  PRO_HEADER: `Become PRO supporter!`,
  PRO_TEXT: `
 PRO scripts ARE FREE TO EVERYONE! <br>

 HOWEVER, if you find them useful, please feel free to donate us. <br>

 We decided it's better to spend time building PRO features, <br>
 than setting up payment system to charge for them, so <br>
 we'll be giving them for free. <br>

 If you like the feature, consider donating 5$ a month!
 `,
}

export const AD_URL = 'http://deloplen.com/afu.php?zoneid=2940698'
export const BACKEND_URL = `https://gramup-family.herokuapp.com/subscriptions/list`
export const CHARGEBEE_PLAN_ID = 'gramup-family-unlimited'
