import Lazy from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import {
  get_random,
  getCSV,
  download,
  download_array,
  instagramUrl,
  getURL,
  randomTimeout,
  sleep,
  skip,
} from './util'

const populateEmail = user => {
  const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g

  const [email, ...others] = Object.values(user)
    .filter(value => typeof value == 'string')
    .flatMap(value => value.match(emailRegexp) || [])
  console.log('found emails (using only first):', [email, ...others])

  return {
    ...user,
    email,
  }
}

export const load_emails_by_hashtag = {
  name: "Download hashtag authors ' emails",
  description: `
    You input #hashtag, we load all the emails of #hashtag publishers

    You can stop script at any time, wait a bit and it should load a csv with all the loaded emails yet
  `,
  params: [
    {
      name: 'hashtag',
      type: 'text',
      prefix: '#',
      labelText: 'Hashtag',
      defaultValue: 'cats',
    },
    {
      name: 'shouldLoadFullName',
      type: 'checkbox',
      prefix: '',
      labelText: 'Download full name',
      defaultValue: 'true',
    },
    {
      name: 'shouldLoadBio',
      type: 'checkbox',
      prefix: '',
      labelText: 'Download bio',
      defaultValue: false,
    },
  ],
  run: async (
    { hashtag, shouldLoadFullName, shouldLoadBio } = {},
    printLog = console.log,
  ) => {

    const _sleep_timeout = window._sleep_timeout
    window._sleep_timeout = 1

    // Phase 1: set up feed generator
    const photos_list = instagram.page_generator({
      method: 'get_hashtag_feed',
      params: [hashtag],
    })

    // Phase 2: pages to list
    const users = new Lazy(photos_list)
      .peek((page, index) =>
        printLog(
          `Batch ${index} of photos loaded: ${page.items.length}`,
        ),
      )
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .map(page => makeGenerator(page.items))
      .flat()
      .map(item => item.user)

    const emails_container = users
      .filter(skip(() => instagram.isStopped, printLog))
      .peek((user, index) => printLog(`User #${index}: @${user.username}: `))
      .map(user =>
        instagram
          .request({ method: 'get_user_info', params: [user.pk] })
          .then(({ user }) => user),
      )
      .peek(user => printLog(`ok`, false))
      .map(user => populateEmail(user))
      .map(({ pk, username, full_name, email, bio }) => {
        const data = { pk, username, email }

        if (shouldLoadFullName) data.full_name = full_name
        if (shouldLoadBio) data.bio = bio

        return data
      })
      .peek(user =>
        printLog(`, email: ${user.email || 'none (skip)'}`, false),
      )
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .filter(user => !!user.email)
      .peek(user => console.log('user', user))

    const emails = await emails_container.unwrap()

    window._sleep_timeout = _sleep_timeout

    printLog(`Emails for #${hashtag} loaded: ${emails.length}`)
    printLog(`You can access them in window.emails or download using`)
    printLog(`\t\tdownloadCSV()`)
    printLog(`or`)
    printLog(`\t\tdownload('hashtag_${hashtag}_emails.csv', getCSV(emails))`)

    window.emails = emails
    window.downloadCSV = () => {
      download_array(emails, `hashtag_${hashtag}_emails`)
    }

    downloadCSV()

    try {
      localStorage.setItem(`hashtag_${hashtag}_emails`, JSON.stringify(emails))
    } catch (err) {
      printLog(`You can ignore this error: Cant save to localStorage: file too big.`)
    }
  },
}

export default load_emails_by_hashtag
