import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { message } from '../../constants'

export default class ProBadge extends React.Component {
  state = {
    isOpened: false,
  }

  togglePayment = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    })
  }

  render() {
    const { isOpened } = this.state
    const { isBanner, children, className } = this.props

    if (!isBanner) {
      return (
        <div
          className={className || 'btn btn-success'}
          data-tip={`${message.PRO_HEADER}<br><br><br>${message.PRO_TEXT}`}
          data-multiline="true"
          data-place="top"
          data-effect="solid"
          data-class="text-sm"
          onClick={() => ReactTooltip.hide()}
        >
          {children ? (
            children
          ) : (
            <Link className="text-decoration-none text-white" to="/support-us">
              <i className="fa fa-star"></i>
              <span>PRO</span>
            </Link>
          )}
        </div>
      )
    }

    return (
      <div className="d-flex justify-between pt-4 pb-2">
        <div className="col-auto">
          <Link
            className="btn btn-success"
            to="/support-us"
            data-tip={`${message.PRO_HEADER}<br><br><br>${message.PRO_TEXT}`}
            data-multiline="true"
            data-place="top"
            data-effect="solid"
            data-class="text-sm"
          >
            <i className="fa fa-star"></i>
            <span>PRO</span>
          </Link>
        </div>

        {isOpened && isBanner && (
          <Fragment>
            <div className="col-auto">
              <a
                className="btn btn-danger"
                href="http://paypal.me/okhlopkov/10"
                target="_blank"
              >
                Donate via Paypal
              </a>
            </div>
            <div className="col-auto">
              <a
                className="btn btn-primary"
                href="https://www.patreon.com/join/morejust"
                target="_blank"
              >
                Support via Patreon
              </a>
            </div>
            <div className="col-auto">
              <a
                className="btn btn-warning"
                href="https://liberapay.com/caffeinum/donate"
                target="_blank"
              >
                Donate via Liberapay
              </a>
            </div>
            <div className="col-auto">
              <a
                className="btn btn-warning"
                href="https://buymeacoff.ee/okhlopkov"
                target="_blank"
              >
                Buy us a coffee
              </a>
            </div>
          </Fragment>
        )}
      </div>
    )
  }
}
