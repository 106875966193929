import React from 'react'
import { connect } from 'react-redux'
import { Button } from './Button'
import { sendConfig } from '../../services'
import { updateConfig } from '../../redux/actions'


@connect(state => ({ config: state.config }), { updateConfig })
class JoinFamilyButton extends React.Component {
  joinFamily = async () => {

    const config = await sendConfig({ JOINED_FAMILY: true })
    this.props.updateConfig(config)

  }

  render() {
    const { config } = this.props

    return (
      <Button
        className={`btn btn-icon-split ${(config && !config.JOINED_FAMILY) ? 'btn-danger' : 'btn-primary'}`}
        ym={`family-join`}
        onClick={this.joinFamily}
      >
        <span className="icon text-white">
          <i className="fas fa-check"></i>
        </span>
        <span className="text text-white">
          {!config && ('Loading...')}
          {(config && config.JOINED_FAMILY) && ('Joined!')}
          {(config && !config.JOINED_FAMILY) && (
            this.props.children || 'Join Gram Up! Family'
          )}
        </span>
      </Button>
    )
  }
}

export { JoinFamilyButton }
