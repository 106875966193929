import React from 'react'
import { Card, CardHeader, CardBody } from '../components/markup/Card'
import { JoinFamilyButton } from '../components/dumb/JoinFamilyButton'

export default () => (
  <div className="container-fluid">
    <div className="row">
      <h1>
        Gram Up! Family
        &nbsp;
        <JoinFamilyButton>
          Join the Family!
        </JoinFamilyButton>
      </h1>
    </div>

    <div className="row">

      <div className="col-lg-6">
        <Card className="shadow mb-4">
          <CardHeader className="py-3" id="how">
            What it this?
          </CardHeader>
          <CardBody>
            <p>
              Gram Up! unites many people into a Family community.
              You will automatically like people's photos,
              but in exchange your every photo will receive many likes
              from other members.
            </p>
          </CardBody>
        </Card>
      </div>

      <div className="col-lg-6">
        <Card className="shadow mb-4">
          <CardHeader className="py-3">
            So you will use my account?
          </CardHeader>
          <CardBody>
            <p>
              Gram Up can automatically perform actions on your behalf
              if you grant him this permission. We won’t perform any unsolicited
              activity if you revoke us the access.
            </p>
          </CardBody>
        </Card>
      </div>

      <div className="col-lg-6">
        <Card className="shadow mb-4">
          <CardHeader className="py-3">
            Is it safe?
          </CardHeader>
          <CardBody>
            <p>
              We don't store your password – you will be absolutely
              disconnected the moment you log out or turn off your PC.
              If you want to continue using Gram Up! without Family features,
              you can opt out in&nbsp;
              <a href="https://insta.gramup.me/settings" target="_blank">
                Settings
              </a>
            </p>
          </CardBody>
        </Card>
      </div>

      <div className="col-lg-6">
        <Card className="shadow mb-4">
          <CardHeader className="py-3">
            I feel it’s not authentic. I want to receive real likes!
          </CardHeader>
          <CardBody>
            <p>
              Actually, all the likes are from real people.
              We will be setting up a chat to create a Gram Up!
              Family community where you can talk to all
              the users who engage with your content.

              {/*Also, we encourage to use comments too, not only automatic likes.*/}
            </p>
          </CardBody>
        </Card>
      </div>


      <div className="col-lg-6">
        <Card className="shadow mb-4">
          <CardHeader className="py-3">
            Before you proceed
          </CardHeader>
          <CardBody>
            <p>
              Also, you need to meet the following requirements to let all the Family magic happen:
            </p>

            <ul style={{listStyle: 'none', paddingLeft: 0 }}>
              <li>🔹<span>You agree to our</span> <a href="/legal" target="_blank">Terms and Conditions</a></li>
              <li>🔹<span>Make your account public</span> <a href="https://instagram.com" target="_blank">instagram.com</a></li>
              <li>🔹<span>Install our Gram Up! extension:</span>
                <a href="https://get.gramup.me">get.gramup.me</a>
              </li>
              <li>🔹Your PC needs to be connected to receive likes and comments!</li>
              <li>🔹RECEIVE FREE LIKES</li>
            </ul>

            <p>
              If you still have any questions, check out our channel or ask in the chat:
              &nbsp;
              <a href="https://t.me/instabotproject" target="_blank">https://t.me/instabotproject</a>
            </p>

          </CardBody>
        </Card>
      </div>

      <div className="col-lg-6">
        <Card className="shadow mb-4">
          <CardHeader className="py-3">
            That sounds awesome, how do I enter?!
          </CardHeader>
          <CardBody>
              <p>
                You can join now by pressing this button!
              </p>

              <p>
                <JoinFamilyButton>
                  Join the Family!
                </JoinFamilyButton>
              </p>

              <p>
                <i>By clicking this button, you allow us to control your account for improving other users' engagement.</i>
              </p>

            </CardBody>
          </Card>
        </div>


        <div className="col-lg-12">
          <Card className="shadow mb-4" id="tiers">
            <CardHeader className="py-3">
              Tiers
            </CardHeader>
            <CardBody>
              <p>
                Couple of notes on Gram Up! Family.
              </p>

              <ol>
              <li> <strong>Free tier – $0/month</strong> </li>
              Get up to 50 likes on every new Instagram post.
              <br />
              <br />
              <JoinFamilyButton />
              <br />
              <br />
              Join by pressing the button!
              <br /><br />

              <li> <strong>Unlimited tier – $10/month</strong> </li>
              Get unlimited likes on every new Instagram post.
              <p>
              The exact amount depends on Gram Up Family size,
              but we have 250+ participants already, even before release!
              </p>
              <p>
              To join, become our patron or just ping us in the chat on the right
              </p>
              <a href="https://patreon.com/join/morejust" data-patreon-widget-type="become-patron-button" target="_blank">
                Become a Patron!
              </a>
              <br /><br />

              <li> <strong>Business tier – $50/month</strong> </li>

              Get unlimited likes on every new Instagram post.
              <p>
              But. We will not use your account to like others' posts.
              Moreover, you don’t even need to install our extension
              to get the engagement. We need just your Instagram username.
              </p>

              <p>
                <a href="https://patreon.com/join/morejust" data-patreon-widget-type="become-patron-button" target="_blank">
                  Become a Patron
                </a>
                <span>
                  {' '}
                  or
                  {' '}
                </span>
                <a href="https://t.me/instabotproject" data-patreon-widget-type="become-patron-button" target="_blank">
                  Contact Us
                </a>
              </p>

              </ol>

              <p>
                All the waitlist participants are already at <strong>Unlimited</strong> tier for the next one month.
                You can read more about our tiers at our Patreon page:
                {' '}
                <a href="https://patreon.com/join/morejust" target="_blank">
                  https://patreon.com/join/morejust
                </a>

              </p>

          </CardBody>
        </Card>
      </div>

    </div>

  </div>
)
