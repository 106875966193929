import spintax from 'spintax'

import Lazy, { LazyError } from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import {
  get_random,
  getCSV,
  download,
  instagramUrl,
  getURL,
  randomTimeout,
  sleep,
  skip,
} from './util'

import {
  safeMap,
} from '../old/likeItems'


export const comment_by_hashtag = {
  name: 'Comment photos from hashtag feed',
  description: `
    Will put comments under photos with hashtag.

    It will chose random comment from a list of comments.

    Also supports Spintax!
  `,
  params: [
    { name: 'hashtag', type: 'text', prefix: '#', defaultValue: 'cats' },
    { name: 'nPhotos', type: 'number', values: [1, 2, 5, 10, 20, 50, 100, 200, 300], defaultValue: 5 },
    { name: 'comments', type: 'textarea', defaultValue: '🔥 [[username]]\nnice\ngreat {photo|picture}!\nwow' },
  ],
  run: async ({ hashtag, nPhotos, comments }, printLog = console.log) => {
    if (!hashtag) {
      throw new Error(`Empty hashtag field!`)
    }

    printLog(`Fetching photos by hashtag: #${hashtag} ... `)

    const { items } = await instagram.request({
      method: 'get_hashtag_feed',
      params: [hashtag],
    })

    printLog(`OK, ${items.length} results`, false)
    console.log(`URLS:`, items.map(instagramUrl))

    const comment_text = item => {
      const _comment = window.comment_text
        ? window.comment_text(item)
        : get_random(comments.split('\n')).replace('[[username]]', `@${item.user.username}`)

      const comment = spintax.unspin(_comment)

      printLog(`Posting comment to ${instagramUrl(item)} : ${comment}`)

      return comment
    }

    return safeMap(
      items.slice(0, nPhotos),
      item =>
        instagram.request({
          method: 'comment',
          params: [item.id, comment_text(item)],
        }),
      printLog,
    )
  },
}

export default comment_by_hashtag
