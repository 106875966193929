import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Card, CardHeader, CardBody } from '../components/markup/Card'
import { Button } from '../components/dumb/Button'

import { userUrl } from '../scripts/util'

import { fetchSubscriptionData, isValid } from '../license'
import { updateSubscriptions } from '../redux/actions'

export const SubscriptionPage = props => {
  const fetchStatus = async username => {
    if (!props.user) {
      return
    }

    const subs = await fetchSubscriptionData(props.user.username) || []

    props.updateSubscriptions(subs)
  }

  useEffect(() => {
    fetchStatus()
  }, [props.user])

  if (!props.user) {
    return 'Not logged in'
  }

  return (
    <div className="container-fluid">
      <h1>My Subscription</h1>

      <div className="row">
        <div className="col-lg-12">
          <Card className="shadow mb-4">
            <CardHeader className="py-3">
              Subscriptions for{' '}
              <a
                href={userUrl(props.user)}
                target="_blank"
                rel="noreferrer noopener"
              >
                @{props.user.username}
              </a>
            </CardHeader>
            <CardBody>
              <Button
                className="btn-outline-primary"
                onClick={() => fetchStatus()}
              >
                Update
              </Button>

              {!props.subscriptions.length && (
                <div>
                  <br />
                  No subscriptions, buy here:
                  <br />
                  <Button className="btn-danger" onClick={() => window.open(`https://patreon.com/morejust`)}>
                    PURCHASE
                  </Button>
                </div>
              )}

              {props.subscriptions.map(s => (
                <pre key={s.id}>
                  {'\n'}
                  Status: {s.status}
                  {'\n'}
                  Trial: {s.isTrialValid ? 'Valid' : 'Not valid'}
                  {'\n'}
                  License: {s.isValid ? 'Valid' : 'Not valid'}

                  {'\n\n'}
                  {JSON.stringify(s, null, 4)}
                </pre>
              ))}
            </CardBody>
          </Card>
        </div>
      </div>

    </div>
  )
}

export default connect(
  state => ({ user: state.user, subscriptions: state.subscriptions }),
  { updateSubscriptions },
)(SubscriptionPage)
