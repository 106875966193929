import React from 'react'

export const CardHeader = (props) => (
  <div
    className={`card-header ${props.className || ''}`}
    style={props.style}
  >
    {props.shouldNotStyle ? (
      props.children
    ) : (
      <h6 className="m-0 font-weight-bold text-primary">
        {props.children}
      </h6>
    )}

  </div>
)

export default CardHeader
