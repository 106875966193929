import Lazy from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import {
  getURL,
  randomTimeout,
  sleep,
  skip,
  try_json,
} from './util'

export const unfollow_everyone = {
  name: 'Unfollow Everyone Except',
  description: `
    You have three options here:
    - unfollow just everyone
    - unfollow everyone, but with a white list of people who we don't touch

    Uncheck Dry Run only when you're sure you've set up correct parameters. There's no way to undo unfollowings.

    ** in progress **
    - unfollow those who don't follow you, but very slow

    To continue running the previously stopped script, include max_id param.
  `,
  params: [
    {
      name: 'whitelist',
      type: 'textarea',
      labelText: 'DONT UNFOLLOW: Username White List (@username or pk)',
      defaultValue: try_json(localStorage.getItem('unfollow_whitelist'), []).map(u => u.username).join('\n') || 'caffeinum\ndanokhlopkov',
    },
    {
      name: 'max_id',
      type: 'text',
      labelText: 'max_id (leave empty)',
      defaultValue: '',
    },
    {
      name: 'shouldUseWhitelist',
      type: 'checkbox',
      prefix: '',
      labelText: 'Use whitelist',
      defaultValue: true,
    },
    {
      name: 'isDryRun',
      type: 'checkbox',
      prefix: '',
      labelText: 'Dry Run: Only imitate, not unfollow yet',
      defaultValue: true,
    },
  ],
  run: async ({ isDryRun = true, max_id = null, whitelist, shouldUseWhitelist = true }, printLog = console.log) => {
    const {
      user: { pk },
    } = await instagram.request({ method: 'check_login', params: [] })

    const removeWhitespace = str => str.replace(/\s*/ig, '')
    const whitelisted_users = shouldUseWhitelist
      ? whitelist.split('\n').map(removeWhitespace).filter(Boolean)
      : []

    if (!pk || isNaN(pk)) throw new Error(`No user id: ${pk}`)

    // Phase 1: set up generator
    const following_list = instagram.page_generator({
      method: 'get_user_followings',
      params: [pk],
    })

    // Phase 2: paging
    const followings = new Lazy(following_list)
      .peek((page, index) =>
        printLog(`Batch ${index} of followings loaded: ${page.users.length}, max_id=${page.max_id}`),
      )
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .map(page => makeGenerator(page.users))
      .flat()

    // Phase 3: search each in followers
    const follows = followings
      .filter(skip(() => instagram.isStopped, printLog))
      .filter(user => !whitelisted_users.includes(user.username) && !whitelisted_users.includes(user.pk))
      .peek(user => printLog(`User: @${user.username}: `))
      // .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .map(async user => {
        // const sec = randomTimeout()
        // printLog(`Sleeping ${sec.toFixed(1)} sec`)
        // await sleep(sec * 1000)

        printLog(`Unfollowing ${getURL(user)} ...`, false)
        return instagram.request({ method: 'unfollow', params: [user.pk] })
      })
      .peek(({ status }) => printLog(status || 'error', false))
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))

    const full_info = await follows.unwrap()

    window.full_info = full_info

    printLog(`Loaded!`)
    printLog(`You follow ${full_info.length} people.`)

    printLog(`FINISHED,
      Total requests: ${full_info.length},
      Success: ${full_info.filter(item => item.status == 'ok').length} items,
      Errors: ${full_info.filter(item => item.status !== 'ok').length} items`)


  },
}


export default unfollow_everyone
