import { CHARGEBEE_PLAN_ID, BACKEND_URL } from './constants'

export const shouldCheckLicense = () => {
  // return true
  return false
}

export const checkLicenseCode = code => {
  return code && code.replace(' ', '').toUpperCase() === 'RE13ASJG'
}

export const checkTrial = installedAt => {
  return Date.now() < installedAt + 1000 * 60 * 60 * 24 * 7
}

export const isTrialValid = (subscription = {}) => {
  const trialEndTime = parseInt(subscription.trial_end) * 1000

  return Date.now() <= new Date(trialEndTime)
}

export const isLicenseValid = (subscription = {}) => {
  const paidEndTime = parseInt(subscription.current_term_end) * 1000

  return Date.now() <= new Date(paidEndTime)
}

export const isValid = (subscription = {}) => {
  return subscription.status == 'active'
      || subscription.status == 'in_trial'
      // || subscription.status && subscription.status != 'cancelled'
      || isTrialValid(subscription)
      || isLicenseValid(subscription)
}

export const checkHasAccess = async username => {
  const subs = await fetchSubscriptionData(username)

  return subs.filter(isValid).length > 0
}

export const fetchSubscriptionData = async username => {
  if (!username) {
    return
  }

  const url = `${BACKEND_URL}/?username=${username}`

  const { results = [] } = await fetch(url).then(res => res.json())

  const subscriptions = results
    .filter(s => s.plan_id === CHARGEBEE_PLAN_ID)
    .map(s => ({
      isTrialValid: isTrialValid(s),
      isValid: isValid(s),
      ...s,
    }))
    // .sort((s1, s2) => s2.isValid ? s1.isValid ? +1 : -1 : -1)
    .sort((s1, s2) => s2.current_term_end - s1.current_term_end)

  console.log('sorted_subscriptions', subscriptions)

  return subscriptions
}
