/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'react-router-dom'
import { InlineButton } from '../dumb/Button'
import { Card, CardHeader, CardBody } from './Card'

export default class LogCard extends React.Component {
  state = {
    autoScroll: this.props.autoScroll,
  }

  scrollToBottom = (force = false) => {
    if (!this.state.autoScroll && !force) return

    const target = this.messagesEnd
    target.parentNode.scrollTop = target.offsetTop
  }

  toggleAutoScroll = () => {
    !this.state.autoScroll && this.scrollToBottom()

    this.setState({
      autoScroll: !this.state.autoScroll,
    })
  }

  convertTextURL = text => {
    try {
      const [_, head, protocol, url, tail] = text.match(
        /^(.*)(https:\/\/)(.*?)(\s.*)?$/,
      )

      return (
        <span>
          {head}
          <a href={`https://${url}`} target="_blank">
            {`https://${url}`}
          </a>
          {tail}
        </span>
      )
    } catch (err) {
      console.error('Error destructuring text', text, err)
    }
  }

  convertRawURL = text => {
    try {
      const [_, head, url, params, link_text, tail] =
        text.match(/^(.*)<a href="(.*?)"(.*)>(.*)<\/a>(.*)$/) || []

      if (!_) return text

      return (
        <span>
          {head}
          <a href={url} target="_blank">
            {link_text}
          </a>
          {tail}
        </span>
      )
    } catch (err) {
      console.error('Error destructuring text', text, err)
    }
  }

  convertLocalURL = text => {
    try {
      const [_, head, url, link_text, tail] =
        text.match(/^(.*)<Link to="(.*?)">(.*)<\/Link>(.*)$/) || []

      if (!_) return text

      return (
        <span>
          {head}
          <Link to={url}>{link_text}</Link>
          {tail}
        </span>
      )
    } catch (err) {
      console.error('Error destructuring text', text, err)
    }
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  render() {
    return (
      <Card className="shadow mb-4">
        {/* <!-- Card Header - Dropdown  --> */}
        <CardHeader
          shouldNotStyle
          className="py-3 d-flex flex-row align-items-center justify-content-between"
        >
          <h6 className="m-0 font-weight-bold text-primary">
            <Link to="/logs">Logs</Link>
          </h6>

          {this.props.clearLog && !!this.props.log.length && (
            <InlineButton
              className="m-0 text-danger"
              onClick={() => confirm(`Clear log?`) && this.props.clearLog()}
            >
              Clear Log
            </InlineButton>
          )}

          <InlineButton
            className="m-0"
            onClick={() => this.scrollToBottom(true)}
          >
            Scroll to End
          </InlineButton>

          <InlineButton
            className="m-0 text-warning"
            onClick={() => this.toggleAutoScroll()}
          >
            {this.state.autoScroll ? (
              <i className="fas fa-toggle-on" />
            ) : (
              <i className="fas fa-toggle-off" />
            )}
            &nbsp;
            {/* ' ' */}
            Autoscroll
          </InlineButton>
        </CardHeader>
        {/* <!-- Card Body  --> */}
        <CardBody style={{ height: '70vh', overflowY: 'scroll' }}>
          {this.props.log.map((piece, index) =>
            piece == `<br>` ? (
              <br key={index} />
            ) : (
              <span key={index}>
                {piece.includes('<a href')
                  ? this.convertRawURL(piece)
                  : piece.includes('<Link to')
                  ? this.convertLocalURL(piece)
                  : piece.includes('https://')
                  ? this.convertTextURL(piece)
                  : piece}
              </span>
            ),
          )}

          <div
            style={{ float: 'left', clear: 'both' }}
            ref={el => {
              this.messagesEnd = el
            }}
          />
        </CardBody>
      </Card>
    )
  }
}
