import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import instagram from '../old/instagram_connector'
import scheduler from '../scheduler'

import { Button, InlineButton } from '../components/dumb/Button'
import { Card, CardHeader, CardBody } from '../components/markup/Card'

import { CONNECTION, message } from '../constants'
import { printLog, updateConfig, activateLicense } from '../redux/actions'
import { download } from '../scripts/util'
import { sendConfig } from '../services'

@connect(
  ({
    stats, log, user, connection, config,
    installedAt, verificationCode,
    isLicenseValid, isTrialValid, isCodeValid,
  }) => ({
    stats, log, user, connection, config,
    installedAt, verificationCode,
    isLicenseValid, isTrialValid, isCodeValid,
  }),
  { printLog, updateConfig, activateLicense },
)
class SettingsPage extends React.Component {
  state = {
    info: {},
    config: null,
  }

  unfollowWhitelistRef = React.createRef()

  async componentDidMount() {
    const info = await instagram.request({ method: 'version' }, true)

    this.props.updateConfig(info.config)

    this.setState({
      info,
      config: info.config,
    })
  }

  async componentDidUpdate() {
    if (this.props.connection.status !== CONNECTION.LOGGED_IN) { return }
    if (this.state.info.status) { return }

    const info = await instagram.request({ method: 'version' }, true)

    this.props.updateConfig(info.config)

    this.setState({
      info,
      config: info.config,
    })

  }

  updateConfig = async (_updates) => {
    this.setState({
      config: null,
    })

    const new_config = await sendConfig(_updates)

    console.log('config', new_config)

    this.setState({
      config: new_config,
    })
  }

  render() {
    const { info, config } = this.state
    const { stats, user, log, connection } = this.props

    const {
      installedAt, verificationCode,
      isLicenseValid, isTrialValid, isCodeValid,
    } = this.props

    let unfollow_whitelist = []

    try {
      unfollow_whitelist = JSON.parse(localStorage.getItem('unfollow_whitelist')) || []
    } catch (err) {

    }

    return (
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          {/* <!-- Page Heading  --> */}
          <h1 className="h3 mb-0 text-gray-800">Settings</h1>
        </div>

        {/* <!-- Content Row  --> */}
        <div className="row">
{/* 
          <div className="col-lg-6">
            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                License
              </CardHeader>
              <CardBody>
                <table>
                  <tbody>
                  <tr>
                    <td>
                      Installed At
                    </td>
                    <td>
                      {new Date(installedAt).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Trial Active until
                    </td>
                    <td>
                      {new Date(installedAt + 1000 * 60 * 60 * 24 * 7).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Trial
                    </td>
                    <td>
                      {isTrialValid ? 'Active' : 'Passed'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      License
                    </td>
                    <td>
                      {isCodeValid ? 'Valid' : 'Invalid'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Verification Code
                    </td>
                    <td>
                      {verificationCode || 'none'}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Extension Active
                    </td>
                    <td>
                      {isLicenseValid ? 'Yes' : 'No'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Get License:
                    </td>
                    <td>
                      <Button
                        className="btn-primary"
                        onClick={() => window.open(`https://patreon.com/morejust`)}
                        ym={`buy-license`}
                      >
                        <span className="text">Become PRO</span>
                      </Button>
                    </td>
                  </tr>

                  </tbody>
                </table>

              </CardBody>
            </Card>
          </div> */}

          <div className="col-lg-6">

            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Like/Follow sleep interval
              </CardHeader>
              <CardBody>
                Better safe than sorry!

                NOTE: This setting is reset after page update

                <br />

                <div className="d-flex align-items-center justify-content-between mt-2">

                  <Button
                    className="btn-danger"
                    onClick={() => alert(`Saved: ${window._sleep_timeout = 20} sec between actions.`)}
                    ym={`sleep-timeout-5-sec`}
                  >
                    <span className="text">Fast ~ 20 sec</span>
                  </Button>

                  <Button
                    className="btn-success"
                    onClick={() => alert(`Saved: ${window._sleep_timeout = 60} sec between actions.`)}
                    ym={`sleep-timeout-20-sec`}
                  >
                    <span className="text">Normal ~ 60 sec</span>
                  </Button>

                  <Button
                    className="btn-primary"
                    onClick={() => alert(`Saved: ${window._sleep_timeout = 120} sec between actions.`)}
                    ym={`sleep-timeout-120-sec`}
                  >
                    <span className="text">Safe ~ 120 sec</span>
                  </Button>

                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-6">
            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Install VPN
              </CardHeader>
              <CardBody>
                <p>
                  Try Betternet VPN:
                </p>
                <p>
                  <a
                    href="https://chrome.google.com/webstore/detail/betternet-unlimited-free/gjknjjomckknofjidppipffbpoekiipm"
                    target="_blank"
                  >
                    https://chrome.google.com/webstore/detail/betternet-unlimited-free/gjknjjomckknofjidppipffbpoekiipm
                  </a>
                </p>

              </CardBody>
            </Card>
          </div>

          <div className="col-lg-6">
            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Gram Up! Family
              </CardHeader>
              <CardBody>
                {!config && (
                  <p>
                    Loading config...
                  </p>
                )}

                {config && (
                  <p>
                    <input
                      type="checkbox" defaultChecked={config.JOINED_FAMILY}
                      onChange={() => this.updateConfig({ JOINED_FAMILY: !config.JOINED_FAMILY })}
                    />
                    &nbsp;
                    <span onClick={() => this.updateConfig({ JOINED_FAMILY: !config.JOINED_FAMILY })}>
                      Joined Family?
                    </span>

                    <br />

                    <input
                      type="checkbox" defaultChecked={!config.NOT_BETA_TEST}
                      onChange={() => this.updateConfig({ NOT_BETA_TEST: !config.NOT_BETA_TEST })}
                    />
                    &nbsp;
                    <span onClick={() => this.updateConfig({ NOT_BETA_TEST: !config.NOT_BETA_TEST })}>
                      Allow testing new features
                    </span>
                  </p>
                )}

                <p>
                  Requires version >= 1.4.14

                  &nbsp;
                  <a
                    href="https://github.com/instagrambot/GramUp/wiki/How-to-Update"
                    target="_blank"
                  >How to Update?</a>

                </p>
                <p>
                  <a
                    href="https://github.com/instagrambot/GramUp/wiki/Gram-Up!-Family"
                    target="_blank"
                  >What is Gram Up! Family?</a>
                </p>
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-6">
            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Scheduled tasks
              </CardHeader>
              <CardBody>
                {!scheduler.tasks.length && (
                  <div>
                    No tasks, create at <Link to="/scripts/schedule">Schedule</Link>
                  </div>
                )}

                {scheduler.tasks.length !== 0 && (
                  <table style={{ margin: '0', overflow: 'scroll', width: '100%' }}>
                    <thead>
                      <tr>
                        <th>
                          scheduled
                        </th>
                        <th>
                          script
                        </th>
                        <th>
                          actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {scheduler.tasks.map((task, index) => {
                        const { startAt, scriptName, params } = task

                        return (
                          <tr key={index}>
                            <td>
                              {new Date(startAt).toLocaleString()}
                            </td>
                            <td>
                              <Link to={`/scripts/${scriptName}`}>
                                {' '}{scriptName}{' '}
                              </Link>
                            </td>
                            <td>
                              <Link to={`/scripts/schedule?scriptName=${scriptName}&scriptParams=${JSON.stringify(params)}`}>
                                duplicate
                              </Link>
                            </td>
                            <td>
                              <InlineButton onClick={() => confirm('Delete task?') && scheduler.remove(task)}>
                                delete
                              </InlineButton>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}

              </CardBody>
            </Card>
          </div>


          <div className="col-lg-6">
            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Unfollow whitelist
              </CardHeader>
              <CardBody>
                <Link
                  to={user ? `/script/load_followings?username=${user.username}&isWhitelist=true` : '#'}
                >
                  Save My Followings to whitelist
                </Link>

                <textarea
                  ref={this.unfollowWhitelistRef}
                  onFocus={() => {
                    console.log('ref', this.unfollowWhitelistRef)
                    this.unfollowWhitelistRef.current.select()
                  }}
                  defaultValue={
                    unfollow_whitelist
                      ? unfollow_whitelist.map(u => u.username).join('\n')
                      : 'Empty.'
                  }
                >
                </textarea>

              </CardBody>
            </Card>
          </div>


          <div className="col-lg-12">

            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Technical information
              </CardHeader>
              <CardBody>
                <table style={{ margin: '0', display: 'block', overflow: 'scroll' }}>
                  <tbody>
                    <tr>
                      <td>
                        Status
                      </td>
                      <td>
                        {info.status}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Connection
                      </td>
                      <td>
                        {connection.description}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Version
                      </td>
                      <td>
                        {info.version}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        User-Agent
                      </td>
                      <td>
                        <span style={{ margin: '0', display: 'block', overflowX: 'scroll', whiteSpace: 'nowrap' }}>
                          {info.user_agent}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Logs
                      </td>
                      <td>
                        <InlineButton onClick={() => download(`gramup_logs_${Date.now()}.html`, log.join("\n"))}>
                          Download logs ({log.length} lines)
                        </InlineButton>
                      </td>
                    </tr>

                  </tbody>
                </table>

              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default SettingsPage
