import React, { Fragment } from 'react'
import { Card, CardBody } from './Card'

export default class CardFullWidthPage extends React.Component {
  render() {
    return (
      <Fragment>
        {this.props.heading && (
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            {/* <!-- Page Heading  --> */}
            <h1 className="h3 mb-0 text-gray-800">{this.props.heading}</h1>
          </div>
        )}

        {/* <!-- Content Row  --> */}
        <div className="row mb-4">
          <div className="col-xl-12 col-md-12 mb-12">
            <Card className="border-left-primary shadow h-150 py-2">
              <CardBody>
                {this.props.children}
              </CardBody>
            </Card>
          </div>
        </div>
      </Fragment>
    )
  }
}
