import {
  instagramIdToUrlSegment,
  urlSegmentToInstagramId,
} from 'instagram-id-to-url-segment'

import spintax from 'spintax'

import Lazy, { LazyError } from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import {
  get_random,
  getCSV,
  download,
  instagramUrl,
  getURL,
  randomTimeout,
  sleep,
  skip,
} from './util'

import {
  safeMap,
} from '../old/likeItems'


export const comment_by_post = {
  name: 'Comment posts by ID',
  params: [
    { name: 'postIds', type: 'textarea', labelText: 'Post IDs or URLs' },
    // { name: 'nPhotos', type: 'number', values: [1, 2, 3], defaultValue: 1 },
    { name: 'comments', type: 'textarea', defaultValue: 'nice\nwow\ngreat {pic|photo}!' },
  ],
  description: `
    Will put comments under the posts specified by post ID.

    Post ID can be found in the link, e.g. https://instagram.com/p/[post id]/

    It will chose random comment from a list of comments.

    Also supports Spintax!
  `,
  run: async ({ postIds, nPhotos, comments }, printLog = console.log) => {
    if (!postIds) {
      throw new Error(`Empty post id field!`)
    }

    const posts = postIds
      // .replace(/\s*/ig, '')
      .split('\n')
      .filter(code => !!code)
      .map(code => ({
        id: urlSegmentToInstagramId(code),
        code: code,
      }))

    console.log('posts', posts)
    // .map(postIdOrUrl => /https:\/\/www.instagram.com\/p\/(B236qIrg2V0)\/?/ig)

    printLog(`OK, ${posts.length} posts loaded.`)
    // console.log(`URLS:`, items.map(instagramUrl))

    const comment_text = item => {
      const _comment = window.comment_text
        ? window.comment_text(item)
        : get_random(comments.split('\n'))
        //.replace('[[username]]', `@${item.user.username}`)

      const comment = spintax.unspin(_comment)

      printLog(`Posting comment to ${instagramUrl(item)} : ${comment}`)

      return comment
    }

    return safeMap(
      posts,
      item =>
        instagram.request({
          method: 'comment',
          params: [item.id, comment_text(item)],
        }),
      printLog,
    )
  },
}

export default comment_by_post
