export const randint = (a, b) => Math.floor(a + (b-a) * Math.random())

export const get_random = (arr) => {
  const index = randint(0, arr.length)

  return arr[index]
}

export const getCSV = arr =>
  [
    Object.keys(arr[0]),
    ...arr.map(item =>
      Object.keys(arr[0])
        .map(key => item[key])
        .map(s => `"${String(s).replace(/"/g, '')}"`)
        .join(','),
    ),
  ].join('\n')

export const download = (filename, content) => {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(content),
  )
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export const download_array = (arr, name, chunk_size = 200) => {
  for (let page = 0; page <= arr.length / chunk_size; page++) {
    download(
      `${name}_page_${page}.csv`,
      getCSV(arr.slice(page * chunk_size, (page + 1) * chunk_size)),
    )
  }
}

export const instagramUrl = (item = {}) => {
  if (!item.code) return ``
  return `https://instagram.com/p/${item.code}`
}

export const userUrl = (item = {}) => {
  if (!item.username) return ``
  return `https://instagram.com/${item.username}`
}

export const userUrlFromName = (item) => `https://instagram.com/${item}`;

export const getURL = item => {
  const isUser = !!item.username

  return isUser ? userUrl(item) : instagramUrl(item)
}

window._sleep_timeout = 60
window._sleep_amp = 0.9

export const randomTimeout = (mean_t = window._sleep_timeout) =>
  Math.max(0.5, mean_t * (1 + window._sleep_amp * (0.5 - Math.random())))

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const timeout = sec => {
  sec = sec || randomTimeout()

  return sleep(sec * 1000).then(() => sec)
}

export const progressive_sleep = (() => {
  let power = 0

  return async () => {
    power += 1;
    await sleep(5 * 1000 * 2 ** power)
    return 5 * 2 ** power
  }
})()


export const skip = (condition, printLog = console.log) => (item, index) => {
  if (condition && condition(item)) {
    printLog(`Skipping ${index} ${getURL(item)} : Request was skipped`)
    return false
  } else {
    return true
  }
}

export const declension = (oneNominative, severalGenitive, severalNominative, number) => {
  number = number % 100;

  return (number <= 14 && number >= 11)
    ? severalGenitive
    : (number %= 10) < 5
      ? number > 2
        ? severalNominative
        : number === 1
          ? oneNominative
          : number === 0
            ? severalGenitive
            : severalNominative//number === 2
      : severalGenitive
    ;
};

export const declensionOfUsers = (number) => declension('User', 'Users', 'Users', number);

export const try_json = (json_str, default_value = {}) => {
  try {
    const json = JSON.parse(json_str)
    return json || default_value
  } catch (err) {
    return default_value
  }
}
